<!--
data: 
- title: text
- subTitle: text
- text: richtext
- cards: object (title: text, text: richtext, linkText: text, url: text, image: image)
- buttons: object (buttonText: text, url: text)
- bgText: text
- backgroundImage: image
- style: text (light, dark, color)
-->
<template>
	<div class="cs-mosaic-matrix-block cs-block" :class="styleClass">
        <div class="cs-block-base">
			<div class="container">
				<div class="header-content">
                    <h2 class="cs-title" >{{ data.title }}</h2>
					<h3 class="cs-sub-title" v-if="data.subTitle">
						{{ data.subTitle }}
					</h3>
					<span class="cs-text" v-html="data.text"></span>
					<div class="btns" v-if="data.buttons && Object.keys(data.buttons).length">
                        <Buttons :data="data.buttons" :cs_style="data.style" />
					</div>
				</div>
				<div class="row mosaic-matrix-cards justify-content-center">
					<div v-for="(card, index) in data.cards" :key="`mosaic-card-${index}`" class="col-sm-6 col-lg-4 col-xl-3">
						<div class="mosaic-matrix-card">
							<div class="mosaic-matrix-card-content">
								<div>
                                    <div class="image-container">
                                        <img :src="card.image" />
                                    </div>
                                    <div class="text-content">
                                        <h4>
                                            {{ card.title }}
                                        </h4>
                                        <span class="cs-text" v-html="card.text"></span>
                                    </div>
                                </div>
                                <div class="bottom-btns" v-if="card.url && card.linkText && card.linkText != 'undefined'">
                                    <Buttons :data="{ 0: { url: card.url, buttonText: card.linkText } }" :cs_style="data.style" /> 
                                </div>
							</div>
						</div>
					</div>
				</div> 
			</div>
		</div> 
	</div>
</template>

<script>
    import {computed} from '../libs/common-fn';

	export default {
		name: 'MosaicMatrix',
		components: {
			Buttons: () => import("../components/CSUtilsButtons.vue")
		},
		props: {
			data: {
				type: Object,
			default: () => {},
			},
		},
		computed: computed('MosaicMatrix')
	};
</script>

<style lang="scss" scoped>
    @import '../styles/main.scss';

	.cs-mosaic-matrix-block {
		.header-content{
			text-align:center;
			margin-bottom: 10px;
            .btns {
                margin-top: 5px;
                margin-bottom: 30px;
            }
		}
        ::v-deep .button {
            @media (max-width: 500px) {
                justify-content: center !important;
            }
        }
		.mosaic-matrix-card {
            margin-bottom: -30px;
		}
		.mosaic-matrix-card .mosaic-matrix-card-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            color: inherit;
            text-decoration: none;
		}
        .mosaic-matrix-cards{
            .mosaic-matrix-card{
                height: calc(100% - 30px);
                background: $bg_color;
                padding: 15px;
                overflow: hidden;
                margin-bottom: 30px;
                position: relative;
                .image-container{
                    position: relative;
                    text-align: left;
                    &:after {
                        display: block;
                        content: '';
                        padding: 30%;
                    }	
                    img {
                        width: 100%;
                        object-fit: cover;
                        position: absolute;
                        height: 100%;
                    }
                }
                .text-content{
                    padding: 20px 0 0;
                    text-align: center;
                    h4 {
                        @include small-title;
                        margin-bottom: 15px;
                    }
                    span {
                        @include text;
                        display: inline-block;
                        margin-bottom: 15px;
                    }
                    ::v-deep {
                        .button-group {
                            margin: auto;
                        }
                    }
                }
                .bottom-btns {
                    padding: 0 0 10px;
                    ::v-deep {
                        .buttons {
                            display: flex;
                            justify-content: center;
                            .button-group {
                                margin: auto;
                            }
                        }
                    }
                }
            }
    
            .bottom-btns ::v-deep {
                .cs-button {
                    color: $secondary_text_color !important;
                    &:hover {
                        color: $secondary_text_color !important;
                    }
                }
            }
        }
		&.cs-style-{
            &dark {
                .text-content {
                    h4 {
                        color: $secondary_text_color;
                    }

                    .cs-text {
                        color: $secondary_text_color;

                        ::v-deep {
                            * {
                                color: $secondary_text_color;
                            }
                        }
                    }
                }
            }

            &color {
                .mosaic-matrix-card {
                    background: #fff;
                }
            }
		}
	}
</style>